import { createStore, compose } from 'redux'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialState = {
  sidebarShow: true,
  step: 1,
  formData: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'step-change':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, composeEnhancers())
export default store
